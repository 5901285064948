import React, { Component } from "react"
import Layout from "templates/basic/"
import Link from 'components/link'
import parse from 'html-react-parser'
import RadioButton from 'components/form/radiobutton/'
import SEO from 'components/seo'
import style from './questionnaire.module.scss'

export default class Questionnaire extends Component {

	constructor(props) {
		super(props);
		this.state = {
			question: 0,
			showNote: false,
			note: 0,
		};

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(e) {
		let answer = e.target.value;
		let speed = 500;
		if (this.state.question === 0) {
			if (answer === 'no') {
				setTimeout (
					function() {
						this.setState(prevState => ({
							showNote: true,
							note: 0,
						}));
					}
					.bind(this),
					speed
				);
			} else {
				setTimeout (
					function() {
						this.setState(prevState => ({
							question: prevState.question + 1,
						}));
					}
					.bind(this),
					speed
				);
			}
		} else if (this.state.question > 0 && answer === 'yes') {
			setTimeout (
				function() {
					this.setState(prevState => ({
						question: prevState.question + 1,
						showNote: true,
						note: 0,
					}));
				}
				.bind(this),
				speed
			);
		} else if (this.state.question === 5 && answer === 'no') {
			setTimeout (
				function() {
					this.setState(prevState => ({
						question: prevState.question + 1,
						showNote: true,
						note: 1,
					}));
				}
				.bind(this),
				speed
			);
		} else {
			setTimeout (
				function() {
					this.setState(prevState => ({
						question: prevState.question + 1,
					}));
				}
				.bind(this),
				speed
			);
		}
	}

	render() {
		const questions = [
			'Do you have an IRS Tax Determination Letter that qualifies your organization for tax exemption under IRS Code Section 501(c)(3) or 170 (b)?',
			'Are you requesting support for an individual or individual family?',
			'Does your organization discriminate on the basis of race, sex, ancestry, sexual orientation, or religion?',
			'Does your organization have a primary purpose of influencing legislation?',
			'Are you requesting support for a capital campaign, endowment or general operating expenses?',
			'Are you requesting support for a school extra-curricular or athletic program?',
		];
		const notes = [
			'Thank you for your interest in the Danos Foundation. Your organization does not meet eligibility requirements at this time.',
			'Thank you for responding to these questions. It appears that you are eligible to submit a grant application to the Danos Foundation. <br />Begin your <a style="text-decoration: underline;" href="/foundation/grant-application/">application now</a>.',
		];
		const radioOptions = [
			{ label: 'Yes', value: 'yes' },
			{ label: 'No', value: 'no' },
		];
		return (
			<>
			<Layout image="/userfiles/Foundation Forms/foundation header v3.jpg" title="Danos GIVES Eligibility Questionnaire" enableDanosFoundation={false} enableFooterTabs={true}>
				<section className="intro">
					<div className="grid-container">
						<div className="grid-x">
							<div className="cell small-12">
								<h1>Non-Profit Eligibility Questionnaire</h1>
								{questions.map((question, index) => (
									<RadioButton className={[((this.state.question === index && !this.state.showNote) ? 'show' : 'hide'), style.radio].join(' ')} onChange={this.handleChange} horizontal={true} label={questions[index]} id={`que_${index}`} name={`que_${index}`} options={radioOptions} />
								))}
								<p className={[(this.state.showNote) ? 'show' : 'hide'].join(' ')}>{parse(notes[this.state.note])}</p>
							</div>
						</div>
					</div>
				</section>
			</Layout>
			<SEO title={'Danos GIVES Eligibility Questionnaire | Danos Foundation'} />
			</>
		)
	}
}