import React, { Component } from 'react';
import style from './radiobutton.module.scss';
import parse from 'html-react-parser';

export default class RadioButton extends Component {

	constructor(props) {
		super(props);
		this.RadioButton = React.createRef();
	}
	render() {
		if (this.props.focusState) {
			this.RadioButton.current.focus({preventScroll: true});
		}
		let name = (this.props.field && this.props.field.name ? this.props.field.name : this.props.name);
		let value = (this.props.field && this.props.field.value ? this.props.field.value : this.props.value);
		let onChange = (this.props.field && this.props.field.onChange ? this.props.field.onChange : this.props.onChange);
		let onBlur = (this.props.field && this.props.field.onBlur ? this.props.field.onBlur : this.props.onBlur);
		let label = this.props.label;
		let errorCondition = (this.props.form && this.props.form.touched[name] && this.props.form.errors[name] ? true : (this.props.errorCondition !== undefined ? this.props.errorCondition : false));
		let errorMessage = (this.props.form && this.props.form.errors[name] ? this.props.form.errors[name] : this.props.errorMessage);
		let autoComplete = (this.props.autoComplete !== undefined ? (this.props.autoComplete === 'off' || this.props.autoComplete === false ? 'off' : 'on') : 'on');
		let options = [];
		let hideLabel = (this.props.hideLabel !== undefined ? this.props.hideLabel : false);
		let horizontal = (this.props.horizontal !== undefined ? this.props.horizontal : false);
		let delimiter = (this.props.delimiter !== undefined ? this.props.delimiter : ',');
		if (value === undefined && this.props.default !== undefined)value = this.props.default;
		if (this.props.options) {
			if (Array.isArray(this.props.options)) {
				for (let option of this.props.options) {
					if (typeof option === 'object') {
						options.push({
							value: option.value,
							label: option.label||option.text,
							checked: (option.value === value),
						})
					}
					else {
						options.push({
							value: option,
							label: option,
							checked: (option === value),
						})
					}
				}
			}
			if (typeof this.props.options === 'string') {
				for (let option of this.props.options.split(delimiter)) {
					options.push({
						value: option,
						label: option,
						checked: (option === value),
					})
				}
			}
		}
		let { className } = this.props;
		className = (className||'').split(' ');
		className.push(style.container);
		className = className.filter((item) => item);
		return (
			<div className={className.join(' ')}>
				{!hideLabel && <label htmlFor={name}>{(label) ? parse(label) : ''}</label>}
				<div ref={this.RadioButton} className={["grid-x"].join(' ')}>
						{options.map((option, index) => {
							return (
								<div className={[((horizontal) ? 'shrink' : ''), 'cell'].join(' ')} key={index}>
									<input
										type='radio'
										name={name}
										value={option.value}
										defaultChecked={option.checked}
										onChange={onChange}
										onBlur={onBlur}
									/>
									<label htmlFor={option.id}>{parse(option.label)}</label>
									<div className={ [style.error, (errorCondition ? style.active : '')].join(' ') }>{(errorMessage) ? parse(errorMessage) : ''}</div>
								</div>
							);
							})
						}
				</div>
			</div>
		)
	}
}